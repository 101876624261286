import React from "react"

export default function Tertiary() {
  return (
    <nav className="tertiary">
      <ul>
        <li>
          <strong>UNISERSE Correduría de Seguros, Sociedad Anónima</strong>
        </li>
        <li>Uruca, San José, Costa Rica</li>
        <li>+506 2519-4500</li>
        <li>info@uniserse.com</li>
      </ul>
    </nav>
  )
}
