import React from 'react'
import _ from 'lodash'
import { useStaticQuery, graphql } from "gatsby"
import Slider from "react-slick";
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { AnchorLink } from "gatsby-plugin-anchor-links";

import './main-banner.scss'

export default function MainBanner() {
  const data = useStaticQuery(graphql`
    query MainBanner {
      allBanners: allWpMainSlider {
        banners: nodes {
          id
          title
          slidersCustomFields {
            enlace
            texto
          }
          image: featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    layout: FULL_WIDTH
                    formats: [AUTO, WEBP, AVIF]  
                  )
                }
              }
            }
          }
        }
      }
    } 
  `)
  const handleSlides = () => _.map(data.allBanners.banners, (banner) => {
    const { id, title, slidersCustomFields: { texto, enlace }, image } = banner
    const imgSrc = getImage(image.node.localFile)
    return (
      <div key={id}>
        <div className="slide-wrapper">
          <GatsbyImage
            image={imgSrc}
            alt={title}
            style={{
              gridArea: "1/1",
              maxHeight: "70vh",
            }}
            layout="fullWidth"
            aspectRatio={3 / 1}
            loading="eager"
          />
          <div className="slide-content">
            <div>
              <h2>{texto}</h2>
              <AnchorLink  className="btn" to={enlace} title="Ver Más" />
              </div>
          </div>
        </div>
      </div>
    )
  })
  const settings = {
    autoplay: true,
    fade: true,
    dots: true,
    infinite: true,
    speed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1
  };
  return (
    <div className="main-banner">
      <Slider {...settings}>
        {handleSlides()}
      </Slider>
    </div>
  )
}
