import React from 'react'
import { Link } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links";

export default function Primary() {
  return (
    <nav className="primary">
      <ul>
        <li><Link to="/acerca">Acerca de Nosotros</Link></li>
        <li><AnchorLink to="/#corredores" title="Nuestros Corredores" /></li>
        <li><AnchorLink to="/#servicios" title="Servicios" /></li>
        <li>Obtener Seguro en línea</li>
        <li><Link to="/publicaciones">Publicaciones</Link></li>
      </ul>
    </nav>
  )
}
