import React from "react"
import Header from "../components/Header"
import Footer from "../components/Footer"

import 'normalize.css';
import "../../assets/styles/global.scss"

export default function Wrapper({ children }) {
  return (
    <div className="wrapper">
      <Header />
      <div className="body">
        {children}
      </div>
      <Footer />
    </div>
  )
}