import React from 'react'
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import MainNav from "../../nav/MainNav"
import MainBanner from "../MainBanner"

import "./header.scss"

export default function Header() {
  return (
    <div className="header-wrapper">
      <div className="header">
        <Link to="/">
          <StaticImage
            src={"../../../assets/images/logo.svg"}
            alt="Uniserse Costa Rica"
            placeholder="blurred"
            layout="fixed"
            className="logo"
            width={200}
            loading="eager"
          />
        </Link>
        <MainNav />
      </div>
      <MainBanner />
    </div>
  )
}
