import React from 'react'
import { StaticImage } from "gatsby-plugin-image"
import { Primary, Secondary, Social, Tertiary } from "../../nav/FooterNav"

import "./footer.scss"

export default function Footer() {
  return (
    <footer className="footer">
      <div className="container">
        <StaticImage
          src={"../../../assets/images/logo.svg"}
          alt="Uniserse Costa Rica"
          placeholder="blurred"
          layout="fixed"
          className="logo"
          width={250}
        />
        <div className="nav-wrapper">
          <div>
            <Primary />
          </div>
          <div>
            <h3>Seguros Personales</h3>
            <button className="btn">Ver Todos</button>
          </div>
          <div>
            <h3>Seguros Empresariales</h3>
            <button className="btn">Ver Todos</button>
          </div>
          <div>
            <Secondary />
          </div>
          <div>
            <Tertiary />
            <Social />
          </div>
        </div>
        <div className="sugese-wrapper">
          <StaticImage
            src={"../../../assets/images/sugese-logo.png"}
            alt="Sugese logo"
            placeholder="blurred"
            layout="fixed"
            className="logo-sugese"
            width={60}
            height={60}
          />
          <small>Licencia: SC-12-115</small>
        </div>
      </div>
    </footer>
  )
}
