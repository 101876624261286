import React from 'react'

export default function Social() {
  return (
    <nav className="social">
      <ul>
        <li><a href="facebook.com" className="ico facebook" target="_blank" rel="noreferrer">Facebook</a></li>
        <li><a href="instagram.com" className="ico instagram" target="_blank">Instagram</a></li>
        <li><a href="https://wa.me/50660604779" className="ico whatsapp" target="_blank" rel="noreferrer">Whatsup</a></li>
        <li><a href="linkedin.com" className="ico linkedin" target="_blank">Linkedin</a></li>
      </ul>
    </nav>
  )
}
