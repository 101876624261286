import React, { useState } from 'react'
import { Link } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links";

import { FaUserCircle as IcoUser } from "react-icons/fa";
import { AiOutlineMenu as IcoMenu, AiOutlineClose as IcoClose } from "react-icons/ai";

import "./main-nav.scss"

export default function MainNav() {

  const [menuToogle, setMenuToogle] = useState(false);
  const handleCssClass = menuToogle ? "menu-on" : "menu-off"

  const handleMenu = () => {
    window.onscroll = () => setMenuToogle(false)
    return setMenuToogle(!menuToogle)
  }

  const triggerBtn = () => {
    return !menuToogle
      ?
      (
        <button
          className="menu-toogle"
          type="button"
          onClick={() => handleMenu()} >
          <IcoMenu />
          <span>Menu</span>
        </button>
      )
      :
      (
        <button
          className="menu-toogle"
          type="button"
          onClick={() => handleMenu()} >
          <IcoClose />
          <span>Cerrar</span>
        </button>
      )
  }

  return (
    <nav className="main-nav">
      {triggerBtn()}
      <ul className={handleCssClass}>
        <li><Link to="/acerca">Nosotros</Link></li>
        <li><AnchorLink to="/#proveedores" title="Proveedores" /></li>
        <li><AnchorLink to="/#corredores" title="Nuestros Corredores" /></li>
        <li><AnchorLink to="/#servicios" title="Servicios" /></li>
        <li><a href="https://www.sugese.fi.cr/seccion-polizas-registradas/p%C3%B3lizas-vigentes" target="_blank" rel="noreferrer">Registro de pólizas Sugese</a></li>
        <li><a href="http://m.uniserse.com/">Asistente Uniserse</a></li>
        <li className="cta"><AnchorLink to="/#contacto" title="Contáctenos" /></li>
        <li className="float-link"><a href="http://www.uniserse.com/"><IcoUser />Iniciar sesión</a></li>
      </ul>
    </nav>
  )
}
