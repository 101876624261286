import React from 'react'

export default function Secondary() {
  return (
    <nav className="secondary">
      <ul>
        <li>Programas Internacionales</li>
        <li>Gestión de Reclamos</li>
        <li>Charlas de Capacitaciones</li>
        <li>Consultorias (Actual vs Óptimo)</li>
      </ul>
    </nav>
  )
}
